import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

const TheShoyaright = () => {
  return (
    <div className="container">
      <Helmet>
        <title> The Shoyaright! </title>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@zuffshoya" />
        <meta name="twitter:title" content="The Shoyaright! Volume 2" />
        <meta
          name="twitter:image"
          content="http://shoya.co/static/shoyaright_icon_twitter_card_large_summary-ce9c728db92ba633cc195662666f7a8f.png"
        />
        <meta name="twitter:image:alt" content="The Shoyaright! Logo" />
      </Helmet>
      <div id="shoyarightChomsky" />
      <div id="lastCat" className="categories">
        MUSIC POLITICS CINEMA ART TELEVISION CUISINE TECHNOLOGY SPORTS NATURE
      </div>
      <div className="articleList">
        <p>
          <Link to="/shoyaright/vol2/shoya-borealis">Shoya Borealis</Link>
        </p>
        <p>
          <Link to="/shoyaright/vol2/useful-triangle">A Useful Triangle</Link>
        </p>
        <p>
          <Link to="/shoyaright/vol2/street-zoology">Street Zoology</Link>
        </p>
        <p>
          <Link to="/shoyaright/vol2/nerve-of-people">
            The Nerve of Some People
          </Link>
        </p>
        <p>
          <Link to="/shoyaright/vol2/discipline-why-how">
            Discipline: Why? & How?
          </Link>
        </p>
        <p>
          <Link to="/shoyaright/vol2/earths-core">
            Shoya, Inc. to Purchase Earth's Core
          </Link>
        </p>
        <p>
          <Link to="/shoyaright/vol2/black-nonfiction-cinema">
            Black Nonfiction Cinema
          </Link>
        </p>
        <p>
          <Link to="/shoyaright/vol2/water-wars">The Water Wars</Link>
        </p>
        <p>
          <Link to="/shoyaright/vol2/replacing-sixers">
            3 Sixers I Could Replace
          </Link>
        </p>
        <p>
          <Link to="/shoyaright/vol2/ultimate-maga">
            A Glimpse into the Other Side
          </Link>
        </p>
        <p>
          <Link to="/shoyaright/vol2/the-arrival-of-drogas">
            The Arrival of Drogas
          </Link>
        </p>
        <p>
          <Link to="/shoyaright/vol2/top5-anime">Top 5 Anime of All Time</Link>
        </p>
        <p>
          <Link to="/shoyaright/vol2/poet-foreigner-prisoner">
            {" "}
            The Poet, the Foreigner, or the Prisoner?{" "}
          </Link>
        </p>
        <p>
          <Link to="/shoyaright/vol1/">Volume 1</Link>
        </p>
        <p></p>
      </div>
      <div className="copyright">
        <p>&copy; Shoya, Inc. 2020 - &infin;</p>
      </div>
    </div>
  )
}

export default TheShoyaright
